<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      list: Array,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const batchId =
        this.getQueryString("batchId") != null
          ? this.getQueryString("batchId")
          : localStorage.getItem("batchId");
      this.$axios.get("/show/getBusiType?batchId=" + batchId).then((res) => {
        const busiType = res.data?.retData?.busiType || null;
        if (busiType == "1") {
          this.$router.push({ name: "index" });
        } else {
          this.$router.push({ name: "product" });
        }
      });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substring(1).match(reg);
      if (r != null) {
        localStorage.setItem("batchId", unescape(r[2]));
        return unescape(r[2]);
      }
      return null;
    },
  },
};
</script>
<style scoped>
</style>

