<template>
  <div class="product" v-loading="loading">
    <div v-show="contentshow">
      <div><img src="../assets/top1.png" alt="" style="width: 100%;"></div>
      <div class="right" v-if="data.safeSign==1" ><img class="right-img" src="../assets/baoxian.png" alt=""></div>
      <div><van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(image, index) in data.picUrls" :key="index">
            <img  :src="image" alt="" style="width: 100%"/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="baccol-div">
        <div class="baccol-span"><span class="prodName">产品名称</span> <span class="prodNameText">{{data.proName}}</span> </div>
        <div class="baccol-span" v-if="data.authType=='1'"><span class="n-enterprise-name">企业名称</span><span class="n-name-content">{{data.merchantName}}</span></div>
      </div>
      <van-tabs v-model="activeName" swipeable color="#0475d7">
        <van-tab title="产品信息">
          <Product v-if="data.newVersion ==0" :list="data"/>
          <ProductNew v-else :list="data"/>
        </van-tab>
        <van-tab v-if="data.authType=='1'" title="企业信息"><Enterprise :list="data"/></van-tab>
        <van-tab title="评定内容"><Information :list="data"/></van-tab>
      </van-tabs>
      
    </div> 
    <div style="display:none">
      <img ref="conf0" src="../assets/distrust.png">
      <img ref="conf1" src="../assets/inconformity.png">
    </div>
  </div>
</template>
<script>
import { removeWatermark, setWaterMark } from '@/components/watermark.js'
import { Notify } from 'vant';
import Product from '@/components/product.vue'
import ProductNew from '@/components/productNew.vue'
import Enterprise from '@/components/enterprise.vue'
import Information from '@/components/information.vue'
export default {
  data(){
    return{
     activeName: '1',
     loading: true,
     data: {},
     contentshow: true
    }
  },
  components:{ Product, Enterprise, Information,ProductNew },
  created(){
    // console.log(this.$route.query.batchId);
    // console.log(this.$route.params.batchId);
    // if()
    this.init()
  },
  methods:{
    handleCheckJump(){
      let batchId = localStorage.getItem('batchId')
      this.$router.push({
        name:'check',
        query:{
          batchId
        }
      })
    },
    init(){
      // localStorage.getItem('batchId')
      let batchId = localStorage.getItem('batchId')
      this.$axios.get('/show/index?batchId='+ batchId).then(res=>{
        let distrust = this.$refs.conf0
        let inconformity = this.$refs.conf1
        if(res.data.retCode == '9999'){
          this.contentshow = false
          Notify({ type: 'warning', message: res.data.retMsg });
          removeWatermark()
        }else{
          if(res.data.retData.resultType == 2){
            setWaterMark(inconformity)
          }else if(res.data.retData.resultType == 3){
            setWaterMark(distrust)
          }else{
          }
          this.data = res.data.retData
        }
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.body{
  background: #F8F8F8;
  height: 100vh;
}
.prodName{
  padding: 3px;
  border: 1px solid #ea6200;
  border-radius: 0.2rem;
  text-align: center;
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  width: 3rem;
  color: #ea6200;
  font-size: 0.65rem;
}
.prodNameText{
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #000;
  font-weight: 600;
  padding-left: 0.12rem;
  word-wrap: break-word;
}
.n-enterprise-name{
  padding: 3px;
  border-radius: 0.2rem;
  text-align: center;
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  width: 3rem;
  font-size: 0.65rem;
  border: 1px solid #484848;
  color: #484848;
}
.n-name-content{
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #000;
  font-weight: 600;
  padding-left: 0.3rem;
  word-wrap: break-word;
}
.baccol-div{
  padding: 10px 20px;
  height: auto;
  margin: auto;
  font-family: "microsoft yahei";
  /* padding: 0 0.9rem; */
  background-color: #e0e3e6;
}
.baccol-span{
  padding: 0.4rem 0 0.05rem;
}
.product>>>.van-tab--active{
  color: #0475d7;
}
.product>>>.van-tab{
  color: #000;
  font-size: 0.9rem;
}
.right-img{
  width: 25%;
  position: absolute;
  top: 15px;
  right: 10px;
}
</style>

